exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-board-of-directors-js": () => import("./../../../src/pages/about/board-of-directors.js" /* webpackChunkName: "component---src-pages-about-board-of-directors-js" */),
  "component---src-pages-about-committees-js": () => import("./../../../src/pages/about/committees.js" /* webpackChunkName: "component---src-pages-about-committees-js" */),
  "component---src-pages-about-governance-js": () => import("./../../../src/pages/about/governance.js" /* webpackChunkName: "component---src-pages-about-governance-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-regional-directors-js": () => import("./../../../src/pages/about/regional-directors.js" /* webpackChunkName: "component---src-pages-about-regional-directors-js" */),
  "component---src-pages-athletes-competitions-js": () => import("./../../../src/pages/athletes/competitions.js" /* webpackChunkName: "component---src-pages-athletes-competitions-js" */),
  "component---src-pages-athletes-js": () => import("./../../../src/pages/athletes.js" /* webpackChunkName: "component---src-pages-athletes-js" */),
  "component---src-pages-athletes-records-js": () => import("./../../../src/pages/athletes/records.js" /* webpackChunkName: "component---src-pages-athletes-records-js" */),
  "component---src-pages-athletes-tour-teams-js": () => import("./../../../src/pages/athletes/tour-teams.js" /* webpackChunkName: "component---src-pages-athletes-tour-teams-js" */),
  "component---src-pages-athletes-training-camps-js": () => import("./../../../src/pages/athletes/training-camps.js" /* webpackChunkName: "component---src-pages-athletes-training-camps-js" */),
  "component---src-pages-clubs-add-js": () => import("./../../../src/pages/clubs/add.js" /* webpackChunkName: "component---src-pages-clubs-add-js" */),
  "component---src-pages-clubs-find-a-club-js": () => import("./../../../src/pages/clubs/find-a-club.js" /* webpackChunkName: "component---src-pages-clubs-find-a-club-js" */),
  "component---src-pages-clubs-js": () => import("./../../../src/pages/clubs.js" /* webpackChunkName: "component---src-pages-clubs-js" */),
  "component---src-pages-coaches-compliance-js": () => import("./../../../src/pages/coaches/compliance.js" /* webpackChunkName: "component---src-pages-coaches-compliance-js" */),
  "component---src-pages-coaches-courses-js": () => import("./../../../src/pages/coaches/courses.js" /* webpackChunkName: "component---src-pages-coaches-courses-js" */),
  "component---src-pages-coaches-index-js": () => import("./../../../src/pages/coaches/index.js" /* webpackChunkName: "component---src-pages-coaches-index-js" */),
  "component---src-pages-coachs-js": () => import("./../../../src/pages/coachs.js" /* webpackChunkName: "component---src-pages-coachs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-officials-bulletins-js": () => import("./../../../src/pages/officials/bulletins.js" /* webpackChunkName: "component---src-pages-officials-bulletins-js" */),
  "component---src-pages-officials-clinics-js": () => import("./../../../src/pages/officials/clinics.js" /* webpackChunkName: "component---src-pages-officials-clinics-js" */),
  "component---src-pages-officials-js": () => import("./../../../src/pages/officials.js" /* webpackChunkName: "component---src-pages-officials-js" */),
  "component---src-pages-officials-positions-js": () => import("./../../../src/pages/officials/positions.js" /* webpackChunkName: "component---src-pages-officials-positions-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-sport-safety-js": () => import("./../../../src/pages/sport-safety.js" /* webpackChunkName: "component---src-pages-sport-safety-js" */),
  "component---src-templates-athletes-detail-js": () => import("./../../../src/templates/athletes/detail.js" /* webpackChunkName: "component---src-templates-athletes-detail-js" */),
  "component---src-templates-clubs-find-a-club-js": () => import("./../../../src/templates/clubs/find-a-club.js" /* webpackChunkName: "component---src-templates-clubs-find-a-club-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/news/detail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-officials-base-positions-js": () => import("./../../../src/templates/officials/base_positions.js" /* webpackChunkName: "component---src-templates-officials-base-positions-js" */),
  "component---src-templates-officials-bulletin-js": () => import("./../../../src/templates/officials/bulletin.js" /* webpackChunkName: "component---src-templates-officials-bulletin-js" */),
  "component---src-templates-officials-certification-js": () => import("./../../../src/templates/officials/certification.js" /* webpackChunkName: "component---src-templates-officials-certification-js" */),
  "component---src-templates-officials-clinic-js": () => import("./../../../src/templates/officials/clinic.js" /* webpackChunkName: "component---src-templates-officials-clinic-js" */),
  "component---src-templates-officials-position-js": () => import("./../../../src/templates/officials/position.js" /* webpackChunkName: "component---src-templates-officials-position-js" */),
  "component---src-templates-simple-about-detail-js": () => import("./../../../src/templates/simple/about-detail.js" /* webpackChunkName: "component---src-templates-simple-about-detail-js" */),
  "component---src-templates-simple-athletes-detail-js": () => import("./../../../src/templates/simple/athletes-detail.js" /* webpackChunkName: "component---src-templates-simple-athletes-detail-js" */),
  "component---src-templates-simple-clubs-detail-js": () => import("./../../../src/templates/simple/clubs-detail.js" /* webpackChunkName: "component---src-templates-simple-clubs-detail-js" */),
  "component---src-templates-simple-coaches-detail-js": () => import("./../../../src/templates/simple/coaches-detail.js" /* webpackChunkName: "component---src-templates-simple-coaches-detail-js" */),
  "component---src-templates-simple-officials-detail-js": () => import("./../../../src/templates/simple/officials-detail.js" /* webpackChunkName: "component---src-templates-simple-officials-detail-js" */),
  "component---src-templates-simple-sport-safety-detail-js": () => import("./../../../src/templates/simple/sport-safety-detail.js" /* webpackChunkName: "component---src-templates-simple-sport-safety-detail-js" */),
  "component---src-templates-simple-sport-safety-simple-js": () => import("./../../../src/templates/simple/sport-safety-simple.js" /* webpackChunkName: "component---src-templates-simple-sport-safety-simple-js" */)
}

